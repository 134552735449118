import "./header.scss";
import "./header.html.twig";
import "./logo.svg";

export default () => {
  const menu = document.querySelector(".header__nav");

  if (!menu) {
    return;
  }
  // const menuButton = document.querySelector(".js-toggle-mobile-menu");
  // if (!menu || !menuButton) return;

  // menuButton.addEventListener("click", () => {
  //   menu.classList.toggle("header__nav--show");
  //   menuButton.classList.toggle("header__nav-icon--active");
  //   document.body.classList.toggle("noscroll");
  //   document.documentElement.classList.toggle("noscroll");
  // });

  // Compare the current page slug with the link destinations of the nav menu items.
  // If there's a match on the start of the path, it must mean this is the active path.
  const path = window.location.pathname;
  const hostname = window.location.origin;
  const navLinks = document.querySelectorAll('.header__nav__link');
  const homeLink = document.querySelector('.header__nav__logo-link');

  Array.from(navLinks).forEach((navLink) => {
    const linkPath = navLink.href.replace(hostname, '');
    if (path === '/') {
      homeLink.classList.add("header__nav__link--active");
      navLink.classList.remove("header__nav__link--active");
    } else
    if (path.indexOf(linkPath) >= 0) {
      navLink.classList.add("header__nav__link--active");
      homeLink.classList.remove("header__nav__link--active");
    } 
  });

}
