import './serviceSummaryList.html.twig';
import './serviceSummaryList.scss';

export default () => {
  const services = document.querySelector('.js-service-summary-list');
  if (!services) return;

  import ('in-view').then(({
    default: inView
  }) => {
    inView('.js-service-summary-card')
      .on('enter', card => {
        card.classList.add('is-visible');
      });
  })
}