import "./parallax.html.twig";
import "./parallax.scss";

export default () => {
  const parallaxItem = document.querySelector(".rellax");

  if (!parallaxItem) return;

  Promise.all([import("rellax"), import("imagesloaded")]).then(
    ([{ default: Rellax }, { default: imagesLoaded }]) => {
      imagesLoaded(".site-wrapper", () => {
        var rellax = new Rellax(".rellax");
      });
    }
  );
};
