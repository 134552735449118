import "./textBlock.scss";
import "./textBlock.html.twig";

export default () => {
  const codeInstances = document.querySelectorAll('.text-block pre');
  if (codeInstances.length) {
    import ('highlight.js/styles/vs2015.css');
    import ('highlight.js').then((hljs) => {
      [...codeInstances].forEach(element => {
        hljs.highlightBlock(element);
      });
    });
  }
}