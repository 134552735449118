import "./articleList.scss";
import "./articleList.html.twig";

export default () => {
  const grid = document.querySelector('.js-article-list');
  if (!grid) return;

  Promise.all([
    import ('isotope-layout'),
    import ('imagesloaded'),
    import ('in-view')
  ]).then(([{
    default: Isotope
  }, {
    default: imagesLoaded
  }, {
    default: inView
  }]) => {

    inView('.js-article-card')
      .on('enter', card => {
        card.classList.add('is-visible');
      });

    imagesLoaded('.js-article-list', () => {
      const layout = new Isotope(grid, {
        itemSelector: '.js-article-card',
        masonry: {
          gutter: 20
        }
      })
    });
  })

}