import "./gridGuide.scss";
import "./gridGuide.html.twig";

export default function() {
  const g = document.querySelector('.grid-guide');
  const stylesheet = document.querySelector('link[href$="main.css"]');
  if (g) {
    const states = ['top', 'off', 'bottom'];
    let state = 0;
    document.addEventListener('keypress', e => {
      if (e.key === 'g') {
        g.className = `grid-guide grid-guide--${states[state++ % states.length]}`;
      }
    });
  }
}