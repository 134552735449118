// cross-browser polyfills
import "babel-polyfill";

// stylesheets
import "./app.scss";

// twig components for compilation
import "./components/footer";
import "./components/heroWithMedia";
import "./components/heroWithMediaAlt";
import "./components/hero";
import "./components/textBlock";
import "./components/blockquote";
import "./components/articleCard";
import "./components/articleList";
import "./components/articleListPager";
import "./components/categoryList";
import "./components/caseStudyPair";
import "./components/caseStudyList";
import "./components/labsCard";
import "./components/callout";
import "./components/ctaStrip";
import "./components/twoColumnText";
import "./components/twoColumnImage";
import "./components/imageContained";
import "./components/imageFull";
import "./components/buttonLink";
import "./components/triangle";
import "./components/hr";
import "./components/highlightsCard";
import "./components/leadMagnetCard";
import "./components/contactCard";
import "./components/headerCtaBlock";
import "./components/serviceSummaryCard";
import "./components/serviceSummaryList";
import "./components/serviceIntroCard";
import "./components/serviceIntroList";
import "./components/softwareLogos";
import "./components/teamCard";
import "./components/teamCardList";
import "./components/locationBlock";
import "./components/hiringCard";
import "./components/timeline";
import "./components/card";
import "./components/borderedText";
import "./components/clientLogoBlock";
import "./components/parallax";
import "./components/wufoo";

import './components/heroFull';
import imageText from './components/imageText';
import gridGuide from './components/gridGuide';

// components that need code initialising
import header from "./components/header";
import articleList from "./components/articleList";
import caseStudyCard from "./components/caseStudyCard";
import caseStudyList from "./components/caseStudyList";
import textBlock from "./components/textBlock";
import teamCardList from "./components/teamCardList";
import serviceSummaryList from "./components/serviceSummaryList";
import parallax from "./components/parallax";

function run() {
  header();
  articleList();
  caseStudyCard();
  caseStudyList();
  textBlock();
  imageText();
  teamCardList();
  serviceSummaryList();
  parallax();
  gridGuide();
}

window.addEventListener("DOMContentLoaded", () => {
  run();
});
