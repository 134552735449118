import './caseStudyList.html.twig';
import './caseStudyList.scss';

export default () => {
  const grid = document.querySelector('.js-case-study-list');
  const headerCtaBlock = document.querySelector('.header-cta-block');

  if (!grid) return;

  Promise.all([
    import ('isotope-layout'),
    import ('imagesloaded'),
    import ('in-view')
  ]).then(([{
    default: Isotope
  }, {
    default: imagesLoaded
  }, {
    default: inView
  }]) => {

    inView('.js-case-study-card')
      .on('enter', card => {
        card.classList.add('is-visible');
      });

    imagesLoaded('.js-case-study-list', () => {

      const offsetSecondBlock = () => {
        const cards = Array.from(document.querySelectorAll('.js-case-study-card'));
        const cardOffset = `${cards[0].clientHeight / 2}px`;
        const secondCard = cards[1];
        secondCard.style.marginTop = cardOffset;
      }

      if (!headerCtaBlock) {
        offsetSecondBlock();
      }

      const layout = new Isotope(grid, {
        itemSelector: '.js-case-study-card',
      })
    });
  });
}