import "./imageText.html.twig";
import "./imageText.scss";

export default () => {
  document.body.classList.add("js-loading");

  window.addEventListener("load", showPage);

  function showPage() {
    document.body.classList.remove("js-loading");
  }

  const media = document.querySelector(".image-text__media");


  const video = document.querySelector("video");

  if (video) {
    const config = {
      threshold: 0.5
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          video.play();
        } else {
          video.pause();
        }
      });
    }, config);
    observer.observe(media);
  }


};
