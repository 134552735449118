import './teamCardList.html.twig';
import './teamCardList.scss';

export default () => {
  const grid = document.querySelector('.js-team-card-list');
  if (!grid) return;

  Promise.all([
    import ('isotope-layout'),
    import ('imagesloaded'),
    import ('in-view')
  ]).then(([{
    default: Isotope
  }, {
    default: imagesLoaded
  }, {
    default: inView
  }]) => {

    inView('.js-team-card')
      .on('enter', card => {
        card.classList.add('is-visible');
      });

    imagesLoaded('.js-team-card-list', () => {
      const layout = new Isotope(grid, {
        itemSelector: '.js-team-card',
        masonry: {
          gutter: 20
        }
      })
    });
  });
}