import './caseStudyCard.html.twig';
import './caseStudyCard.scss';
import './screen.png';

export default () => {
  const el = [...document.querySelectorAll('.case-study-card')];

  if (!el) {
    return;
  }

  el.forEach(caseStudyCard => {
    const img = caseStudyCard.querySelector('img');
    const imgSrc = img.src;
    const media = caseStudyCard.querySelector('.case-study-card__media');

    media.style.backgroundImage = 'url(' + imgSrc + ')';
    img.style.display = 'none';
  });

  // function isInViewport(el) {
  //   const rect = el.getBoundingClientRect();
  //   return (
  //     rect.top >= 0 &&
  //     rect.left >= 0 &&
  //     rect.bottom <=
  //       (window.innerHeight || document.documentElement.clientHeight) &&
  //     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // }

  // const scroll = media => {
  //   document.addEventListener('scroll', function() {
  //     if (isInViewport(media)) {
  //       console.log('IN VIEWPORT');
  //       media.classList.add('in');
  //       // media.style.transform = 'translate(0px, -25px)';
  //     } else {
  //       media.classList.remove('in');
  //       // media.style.transform = 'translate(0px, 0px)'
  //     }
  //   });
  // };


  // el.forEach(caseStudyCard => {
  //   // Setup variables
  //   const img = caseStudyCard.querySelector('img');
  //   const imgSrc = img.src;
  //   const media = caseStudyCard.querySelector('.case-study-card__media');

  //   // Swap image for background image
  //   media.style.backgroundImage = 'url(' + imgSrc + ')';
  //   img.style.display = 'none';

  //   // Event to detect if element is in viewport
  //   scroll(media);
  // });
  
};





